<template>
    <div>
        <div
            ref="customToolbar"
            class="quill-custom-toolbar"
            @mousedown="onMousedownToolbar($event)"
        >
            <button class="ql-bold"/>
            <button class="ql-italic"/>
            <button class="ql-underline"/>
            <select class="ql-color">
                <option value="#F44336"/>
                <option value="#E91E63"/>
                <option value="#9C27B0"/>
                <option value="#2196F3"/>
                <option value="#009688"/>
                <option value="#4CAF50"/>
                <option value="#FFEB3B"/>
                <option value="#FF9800"/>
                <option value="#795548"/>
                <option value="#000000"/>
                <option value="#FFFFFF"/>
            </select>
        </div>
        <div :style="{height: `${height}px`}">
            <div ref="editor" class="quill-editor" />
        </div>
    </div>
</template>

<script>
import Quill from 'quill'

export default {
    props: {
        value: {
            type: String,
            default: ''
        },
        height: {
            type: Number,
            default: 125
        },
        bounds: { // CSS-selector for the element within which bounds the quill toolbar is displayed
            type: String,
            default: 'body'
        }
    },

    data() {
        return {
            initialTextChange: true,
            plainText: null, // plain text, without the tags
            editor: null,
            toolbarOptions: [
                'bold',
                'italic',
                'underline',
                {script: 'super'},
                {script: 'sub'},
                { color: [] }, // if array empty, all default colors included
            ]
        }
    },

    watch: {
        value(newValue, oldValue) {
            //only called if the value is edited from outside, not from editor
            if (this.editor.root.innerHTML !== newValue) {
                this.editor.root.innerHTML = newValue
                this.plainText = this.editor.getText()
            }
        }
    },


    mounted() {
        this.editor = new Quill(this.$refs.editor, {
            modules: {
                toolbar: this.$refs.customToolbar,
            },
            theme: 'bubble',
            bounds: this.bounds,
            formats: [
              'background',
              'bold',
              'color',
              'font',
              'code',
              'italic',
              'link',
              'size',
              'strike',
              'script',
              'underline',
              'blockquote',
              'indent',
              'align',
              'direction',
            ],


        })
        this.editor.keyboard.addBinding({
          key: '-',
          handler: function(range, context) {
            return true;
          }
        })
        this.editor.root.innerHTML = this.value
        this.$nextTick(() => {
            this.editor.on('text-change', this.onTextChange)
            this.editor.on('selection-change', this.onSelectionChange)
            this.initialTextChange = false
        })
    },

    methods: {
        onTextChange(ev) {
          this.plainText = this.editor.getText().trim()
          if (this.plainText === 'NULL') {
            this.editor.root.innerHTML = ''
          }
          if (!this.initialTextChange && this.editor.root.innerHTML !== this.value && this.plainText !== 'NULL') {
            this.$emit('input', this.plainText ? this.editor.root.innerHTML : null)
          }


          // if (ev.ops.length === 1) {
          //   this.initialTextChange = false
          //   return
          // } else {
          //   console.log(this.plainText, this.editor.root.innerHTML)
          //   console.log('aaa', this.plainText ? this.editor.root.innerHTML : null)
          // }
          //   if (this.initialTextChange) {
          //       this.initialTextChange = false
          //       return
          //   }
          //   this.plainText = this.editor.getText().trim()
          // console.log('aaa', this.plainText ? this.editor.root.innerHTML : null)
          //   this.$emit('input', this.plainText ? this.editor.root.innerHTML : null)
        },
        onSelectionChange(e) {
            if (e && e.length) {
                this.$emit('toolbar-show')
            } else {
                this.$emit('toolbar-hide')
            }
        },

        onMousedownToolbar(e) {
            e.preventDefault()
            if (e.target.classList.contains('ql-picker-item')) {
                this.$emit('toolbar-hide')
            }
        }
    }

}
</script>


<style scoped>
    .quill-editor >>> .ql-editor {
        padding: 0 !important;
    }

    .quill-editor >>> .ql-picker-options {
        position: fixed !important;
        top: 0 !important;
        left: 0;
        z-index: 100 !important;
    }

    .quill-custom-toolbar {
        display: flex;
        padding: 2px;
    }


</style>
